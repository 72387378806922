import React, { useEffect, useState } from "react"
import SEO from "../components/SEOComponent"
import { SiteProvider } from "../context"

const Layout = props => {
  const [pageLoaded, setPageLoaded] = useState(false)

  useEffect(() => {
    setPageLoaded(true)
  }, [])
  if (!pageLoaded) return null

  return (
    <SiteProvider
      options={props.pageContext.options}
      region={props.pageContext.region}
      location={props.location}
    >
      <SEO />
      {props.children}
    </SiteProvider>
  )
}

export default Layout
