import React, { useEffect, useMemo, useState } from "react"
import useBreakpoint from "use-breakpoint"

const SiteContext = React.createContext()

export const BREAKPOINTS = { mobile: 0, tablet: 960, desktop: 1280 }

export const SiteProvider = ({
  children,
  region,
  tags,
  setDisableScrolling,
  location,
}) => {
  const params = new URLSearchParams(location.search)
  const [lang, setActiveLang] = useState(params.get("lang") || "en")

  useEffect(() => {
    setActiveLang(params.get("lang"))
  }, [])

  const { breakpoint } = useBreakpoint(BREAKPOINTS)

  const [histories, setHistories] = useState({ prev: null, current: null })

  useEffect(() => {
    setHistories(prev => ({
      prev: prev.current,
      current: location,
    }))
  }, [location])

  const theme = useMemo(() => {
    return {}
  }, [])

  const value = useMemo(
    () => ({
      lang,
      copies: {
        meta_title: "Calvin Klein - Jennie for Calvin Klein",
      },
      tags: tags?.map(t => ({ id: t.id, ...t.data })),
      setDisableScrolling,
      isMobile: breakpoint === "mobile",
      isTablet: breakpoint === "tablet",
      theme,
      histories,
    }),
    [lang, setDisableScrolling, breakpoint, theme, histories]
  )

  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>
}

export const useSiteConfig = () => React.useContext(SiteContext)
