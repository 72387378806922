import React from "react"
import { useSiteConfig } from "../context"

const Seo = () => {
  const { copies } = useSiteConfig()

  return (
    <>
      <title>{copies.meta_title}</title>
    </>
  )
}

export default Seo
